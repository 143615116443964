.taskGroup {
  display: flex;
  width: 100%;
  height: 100svh;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  background: var(--kotini-green-5, #f2fbfa);
}

.nav {
  display: flex;
  width: 306px;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: var(--3, 24px);
  background: #fff;
  @media (max-width: 480px) {
    width: 100%;
  }
}

.progress {
  width: 100%;
}

.toggle {
  display: flex;
  padding: var(--none, 0px) var(--borderRadius-small, 4px);

  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.nextTaskButton {
  display: flex;
  padding: var(--none, 0px);

  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
}

.task {
  width: 100%;
  height: 100%;
}
